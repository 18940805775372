import axios from "axios";

const OpenAICompletionsService = {
  getCompletions: async (messages) => {
    const data = {
      model: "gpt-4-1106-preview",
      messages: messages,
    };

    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        data,
        {
          headers: {
            Authorization: `Bearer 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`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data.choices[0].message.content;
    } catch (error) {
      console.error("Error fetching the OpenAI API:", error);
      throw error;
    }
  },
};

export default OpenAICompletionsService;
