import React, { useState } from "react";
import OpenAICompletionsService from "./OpenAICompletionsService";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import "@fontsource/roboto/400.css";
import "./App.css";

function App() {
  const [openAIResponse, setOpenAIResponse] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    const messages = [
      {
        role: "user",
        content:
          "Create an original retrospective activity that is suitable for virtual attendees and in-person attendees",
      },
    ];

    try {
      const response = await OpenAICompletionsService.getCompletions(messages);
      setOpenAIResponse(response);
    } catch (error) {
      console.error("Error:", error);
      setOpenAIResponse(
        "Failed to get response. Please check the console for more details."
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container style={{ marginTop: "1.5em" }}>
      <Typography style={{ textAlign: "center" }} variant="h2" gutterBottom>
        <a href="http://retrogpt.jessbrock.com">RetroGPT</a>
      </Typography>
      <Typography style={{ textAlign: "center" }} variant="h5" gutterBottom>
        <ul>
          <li>
            This app leverages OpenAI's data models to produce <b>original</b>{" "}
            retrospectives suitable for virtual and in-person settings.
          </li>
          <li>
            Hit the button and behold the magic of artifical intelligence.
          </li>
        </ul>
      </Typography>

      <Button
        onClick={handleFormSubmit}
        variant="contained"
        size="large"
        color="primary"
        style={{ width: "50%", margin: "auto", display: "block" }}
      >
        Generate Retro
      </Button>

      {isLoading && (
        <div>
          <CircularProgress
            style={{
              textAlign: "center",
              display: "block",
              margin: "auto",
              marginTop: "3em",
              marginBottom: "3em",
            }}
          />
          <Typography
            variant="overline"
            style={{ textAlign: "center" }}
            display="block"
            gutterBottom
          >
            <p>
              Please be patient. <div className="mobile-line-break"></div>This
              is low-budget but made with love. 🌈
            </p>
          </Typography>
        </div>
      )}
      <Typography style={{ fontWeight: "bold" }} variant="h6" gutterBottom>
        {openAIResponse && (
          <p>
            <span
              style={{
                fontSize: "1.5em",
                fontStyle: "italic",
                color: "#b53f51",
              }}
            >
              Here's Your Retro!
            </span>{" "}
            {openAIResponse}
            <br />
            <br />
            <Button
              href="http://retrogpt.jessbrock.com"
              variant="contained"
              size="large"
            >
              {" "}
              Go Again?
            </Button>
          </p>
        )}
      </Typography>
      <div />
      <Typography
        variant="overline"
        style={{ textAlign: "center" }}
        display="block"
        gutterBottom
      >
        by <a href="http://jessbrock.com">Jess Brock</a> |{" "}
        <a href="https://gitlab.com/passionately.curious/retrogpt">
          Source Code
        </a>
      </Typography>
    </Container>
  );
}

export default App;
